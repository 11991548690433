<!--  -->
<template>
  <div class="">
    <div class="historychoose" v-show="$route.meta.showfater">
      <!-- <el-tabs :tab-position="tabPosition" type="border-card">
      <el-tab-pane label="单导联心电" class="history_tab"> -->
      <h2>请选择日期范围查询<span>{{ realName }}</span><span style="color:#4da1eb">八导联Holter</span>心电检测记录</h2>
      <el-form ref="form" :model="form">
        <el-col :span="2">
          <div class="blank"></div>
        </el-col>
        <el-form-item>
          <span slot="label">
            <span class="form-name"> 选择起始日期 </span>
          </span>
          <el-col :span="11" class="blank">
            <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%"></el-date-picker>
          </el-col>
        </el-form-item>
        <el-col :span="2">
          <div class="blank"></div>
        </el-col>
        <el-form-item>
          <span slot="label">
            <span class="form-name"> 选择截止日期 </span>
          </span>
          <el-col :span="11" class="blank">
            <el-date-picker type="date" placeholder="选择日期" v-model="form.date2" style="width: 100%"></el-date-picker>
          </el-col>
        </el-form-item>
        <el-col :span="2">
          <div class="blank"></div>
        </el-col>
        <el-form-item>
          <el-button class="xingou-blue" type="primary" @click="querylistByTime()">确认查询</el-button>
          <el-button type="primary" plain @click="queryAll()">查询所有记录</el-button>

          <el-button type="success" @click="download_table()">导出</el-button>

        </el-form-item>
      </el-form>

      <!-- 添加搜索\刷新功能 -->
      <!-- <div style="text-align: right">
            <el-button circle size="small"><i class="el-icon-search"></i></el-button>
            <el-button circle size="small"><i class="el-icon-refresh"></i></el-button>
            <el-button circle size="small"><i class="el-icon-s-grid"></i></el-button>
          </div> -->
      <!-- 表格内容 -->
      <el-table :data="tableData" height="600" :header-cell-style="tableHeaderColor" border stripe style="width: 100%"
        :default-sort="{ prop: 'date', order: 'descending' }" :cell-style="{ fontSize: '16px' }" v-loading="loadingShow"
        element-loading-text="数据正在加载中">
        <el-table-column type="index" align="center">
        </el-table-column>
        <el-table-column prop="createTime" label="记录时间" align="center" sortable>
        </el-table-column>
        <el-table-column prop="totalTime" label="总时长" align="center">
        </el-table-column>
        <el-table-column prop="read" label="Holter记录" align="center">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="medium" style="font-size: 16px;">查看记录
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="broadcast" label="播报结论" align="center">
          <template slot-scope="scope">
            <el-button @click="handleBroadcast(scope.row)" type="text" size="medium" style="font-size: 16px;">查看结论
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="read" label="报告" align="center">
          <template slot-scope="scope">
            <el-button @click="routeReport(scope.row)" type="text" size="medium" style="font-size: 16px;">查看报告
            </el-button>
          </template>
        </el-table-column>

        <!-- 复核模块 -->
        <el-table-column prop="check" label="咨询" align="center" v-if="isCrossPlatJump">
          <template slot-scope="scope">
            <el-popover placement="right" trigger="click">
              <p>输入您的症状描述</p>
              <div style="text-align: right; margin: 0">
                <el-input v-model="scope.row.userDescribe" type="textarea" placeholder="添加症状描述"></el-input>
                <el-button size="mini" type="text">取消</el-button>
                <el-button type="primary" size="mini" @click="Check2Doctor(scope.row)">确定</el-button>
              </div>
              <el-button type="primary" size="medium" style="font-size: 14px;" slot="reference">医生复核
              </el-button>
            </el-popover>
            <el-button type="primary" plain size="medium" style="font-size: 14px;" @click=CheckResult(scope.row)>查看复核
            </el-button>
          </template>
        </el-table-column>

        <!-- 备注模块 -->
        <el-table-column label="备注" :span="24">
        <template slot-scope="scope">
          <el-col :span="18">
            <el-input
              :rows="1"
              placeholder="请输入内容"
              v-model="scope.row.remark"  >
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-button @click="updateRemark(scope.row)" type="primary" >保存</el-button>
          </el-col>
        </template>
      </el-table-column>

        <el-table-column label="删除" align="center">
          <template slot-scope="scope">
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="handleDelete(scope.$index, scope.row)">
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="dialogVisible">
        <el-dialog title="播报记录" :visible.sync="dialogVisible" width="30%" :before-close="handleClose"
          :close-on-click-modal="false">
          <span>下面是您每20秒检测一次的心电图结论</span>
          <div><el-switch v-model="nonZeroFlag" active-text="全部" inactive-text="仅非0值"></el-switch></div>

          <!-- 表格显示方式 -->
          <!-- <el-table :data="broadcastContent" border height="500" max-height="500"
                :default-sort="{ prop: 'date', order: 'descending' }" :cell-style="cellStyle" v-loading="pictLoading"
                element-loading-background=white element-loading-text="数据正在加载中" element-loading-spinner="el-icon-loading">
                <el-table-column v-for="item in DiseaseItem" :prop="item.name" :label="item.label" min-width="8%">
                </el-table-column>
              </el-table> -->
          <!-- 列表显示方式 -->
          <div style="height: 400px; ">
            <el-scrollbar style="height:100%">
              <el-descriptions v-for="sequence in ConclusionItem" :column="1" border>
                <el-descriptions-item v-for="item in sequence" v-if='item.value != 0 || nonZeroFlag' :key="item.label"
                  :label="item.label" :labelStyle="'font-weight:' + (item.name == 'time' ? 'bold' : '') + ';width: 50%'"
                  :contentStyle="'font-weight:' + (item.name == 'time' ? 'bold' : '') + ';background:' + (item.name != 'xinlv' && item.value > 50 ? '#F1948A' : '') + ';color:' + (item.name == 'time' ? '#4da1eb' : '#606266;')">
                  {{ item.value + (item.name == 'time' ? '' : item.name == 'xinlv' ? '次/分钟' : '%') }}
                </el-descriptions-item>
              </el-descriptions>
            </el-scrollbar>
          </div>

          <div style="display: flex; justify-content: center;"> <!-- 居中-->
            <el-button style="text-align: center" type="primary" size="small"
              @click="dialogVisible = false">关闭</el-button>
          </div>
        </el-dialog>

        <el-dialog :visible.sync="checkdataListVisible">
          <p>查看复核结果</p>
          <el-table :data="checkdataList">
            <el-table-column prop="createTime" label="申请复核时间"></el-table-column>
            <el-table-column prop="status" label="复核状态" sortable
              :filters="[{ text: '已复核', value: '1' }, { text: '待复核', value: '0' }]" :filter-method="filterTag"
              filter-placement="bottom-end">
              <template slot-scope="scope">
                <el-tag :type="scope.row.status === '0' ? 'warning' : 'success'" disable-transitions
                  style="font-size: 14px;">{{ scope.row.status === '0' ? '待复核' : '已复核' }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="checkTime" label="复核完成时间"></el-table-column>
            <el-table-column prop="userDescribe" label="症状描述"></el-table-column>
            <el-table-column prop="conclusion" label="复核结论"></el-table-column>
          </el-table>
          <el-button @click="checkdataListVisible = false">取 消</el-button>
          <el-button type="primary" @click="checkdataListVisible = false">确 定</el-button>
        </el-dialog>
      </div>
    </div>
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
export default {
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouterAlive: true,
      dialogVisible: false,
      tabPosition: "left",
      checkdataListVisible: false,
      form: {
        date1: "",
        date2: "",
      },
      tableData: [],
      checkdataList: [],
      broadcastContent: [],
      pictLoading: false,
      header: {},
      isCrossPlatJump: true,

      DiseaseItem: [
        { label: '时间', value: '', unit: '', name: 'time' },
        { label: '心率', value: '', unit: '%', name: 'xinlv' },
        { label: '窦性心动过速', value: '', unit: '%', name: 'xdgs' },
        { label: '窦性心动过缓', value: '', unit: '%', name: 'xdgh' },
        { label: '窦性心律不齐', value: '-', unit: '%', name: 'xlbq' },
        { label: '房性早搏', value: '-', unit: '%', name: 'fxzb' },
        { label: '交界性早搏', value: '', unit: '%', name: 'jjxzb' },
        { label: '室性早搏', value: '', unit: '%', name: 'sxzb' },
        { label: '室上性心动过速', value: '-', unit: '%', name: 'ssxxdgs' },
        { label: '室性心动过速', value: '', unit: '%', name: 'sxxdgs' },
        { label: '交界性逸搏', value: '-', unit: '%', name: 'fxyb' },
        { label: '室性逸搏', value: '', unit: '%', name: 'sxyb' },
        { label: '房颤', value: '', unit: '%', name: 'fc' },
        { label: '房扑', value: '', unit: '%', name: 'fp' },
        { label: '左束支传导阻滞', value: '', unit: '%', name: 'zszcdzz' },
        { label: '右束支传导阻滞', value: '', unit: '%', name: 'yszcdzz' },
        { label: '典型预激综合征', value: '', unit: '%', name: 'dxyjzhz' },
        { label: '短PR综合征', value: '', unit: '%', name: 'dprzhz' },
        { label: '变异型预激综合征', value: '', unit: '%', name: 'byxyjzhz' },
        { label: 'ST段抬高', value: '', unit: '%', name: 'sttg' },
        { label: 'ST段压低', value: '', unit: '%', name: 'styd' },
        { label: 'T波倒置', value: '', unit: '%', name: 'tbxf' },
        { label: 'T波高尖', value: '', unit: '%', name: 'tbgdyc' },
        { label: '病理性Q波', value: '', unit: '%', name: 'blxqb' },
        // { label: '模型方法', value: '', unit: '%', name: 'method' },
      ],
      ConclusionItem: [],
      nonZeroFlag: true,
      loadingShow: true,
      realName: '',
    };
  },
  computed: {
    crossPlatformStatus: {
      get() { return this.$store.state.module.crossPlatform },
      set(val) { this.$store.commit('module/updateCrossPlatform', val) }
    },
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    TimeProcess(time) {
      let y = time.getFullYear();
      let MM = time.getMonth() + 1;
      MM = MM < 10 ? ('0' + MM) : MM;
      let d = time.getDate();
      d = d < 10 ? ('0' + d) : d;
      return y + '-' + MM + '-' + d
    },
    querylistByTime() {
      this.loadingShow = true
      this.startTime = this.TimeProcess(this.form.date1)
      this.endTime = this.TimeProcess(this.form.date2)
      if (this.$store.state.module.crossPlatform) {
        console.log("object11111");
        this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
      }
      this.$http.adornHeader(this.header)
      this.$http({
        url: this.$http.adornUrl("/personal/dynamicecg8/listByTime"),
        method: "get",
        params: this.$http.adornParams({ uid: this.uid, startTime: this.startTime, endTime: this.endTime }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.loadingShow = false
          console.log("看看数据111");
          console.log(data);
          this.datalist = data.dynamicEcgEntities;
          // 列表显示该条数据的总时长
          var tableData = new Array(this.datalist.length)
          tableData = this.datalist
          for (let i = 0; i < tableData.length; i++) {
            var totalTime = new Date(tableData[i].duration * 1000)
            // console.log(totalTime)
            let d = totalTime.getDate() - 1;
            let h = totalTime.getHours() - 8;
            if (h < 0) {
              d = d - 1
              h = 24 + h
            }
            h = h < 10 ? ('0' + h) : h;

            let m = totalTime.getMinutes();
            m = m < 10 ? ('0' + m) : m;
            let s = totalTime.getSeconds();
            s = s < 10 ? ('0' + s) : s;
            if (d != 0) {
              tableData[i].totalTime = d + '天' + h + '小时' + m + '分'; //显示到分
            }
            else if (h == 0) {
              if (m != 0) {
                tableData[i].totalTime = m + '分' + s + '秒';  //显示到秒
              }
              else {
                tableData[i].totalTime = s + '秒';  //显示到秒
              }

            }
            else {
              tableData[i].totalTime = h + '小时' + m + '分' //显示到分
            }
          }
          this.tableData = tableData
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });
    },
    queryAll() {
      this.getDataList()
    },
    //删除功能。目前简单用假数据代替，还不涉及数据库链接
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableData.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    getDataList() {
      if (this.$store.state.module.crossPlatform) {
        console.log("object11111");
        this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
      }
      this.$http.adornHeader(this.header)
      this.loadingShow = true
      this.$http({
        url: this.$http.adornUrl("/personal/dynamicecg8/list"),
        method: "get",
        params: this.$http.adornParams({ uid: this.uid }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.loadingShow = false
          console.log("看看数据");
          console.log(data);
          this.datalist = data.dynamicEcgEntities4eightLeads;
          // 列表显示该条数据的总时长
          var tableData = new Array(this.datalist.length)
          tableData = this.datalist.reverse()
          for (let i = 0; i < tableData.length; i++) {
            var totalTime = new Date(tableData[i].duration * 1000)
            // console.log(totalTime)
            let d = totalTime.getDate() - 1;
            let h = totalTime.getHours() - 8;
            if (h < 0) {
              d = d - 1
              h = 24 + h
            }
            h = h < 10 ? ('0' + h) : h;

            let m = totalTime.getMinutes();
            m = m < 10 ? ('0' + m) : m;
            let s = totalTime.getSeconds();
            s = s < 10 ? ('0' + s) : s;
            if (d != 0) {
              tableData[i].totalTime = d + '天' + h + '小时' + m + '分'; //显示到分
            }
            else if (h == 0) {
              if (m != 0) {
                tableData[i].totalTime = m + '分' + s + '秒';  //显示到秒
              }
              else {
                tableData[i].totalTime = s + '秒';  //显示到秒
              }

            }
            else {
              tableData[i].totalTime = h + '小时' + m + '分' //显示到分
            }
          }
          this.tableData = tableData

        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });
    },
    handleClose(done) {
      this.broadcastContent = []
      done();
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        name: 'HistoryEightHolter2',
        query: {
          "uid": e.uid,
          "dynamicRecordId": e.deid,
          "dynamicRecordCreateTime": e.createTime,
          "dynamicRecorDuration": e.duration,
          "remark":e.remark
        }
      })
    },
    handleBroadcast(e) {
      if (this.$store.state.module.crossPlatform) {
        console.log("object11111");
        this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
      }
      this.$http.adornHeader(this.header)
      // 根据每条心电记录的did获取该条的结论
      this.$http({
        url: this.$http.adornUrl('/personal/dynamicecg8/getConclusion'),
        method: 'get',
        params: this.$http.adornParams({ deid: e.deid })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.pictLoading = false
          this.broadcastContent = data.conclusionData4eightLeads
          // 获取到结论后，判断是新还是老的存储形式,新的就处理一下
          if (this.broadcastContent[this.broadcastContent.length - 1] == ',') {
            this.broadcastContent = "[" + this.broadcastContent.substring(0, this.broadcastContent.length - 1) + "]";
          }
          this.broadcastContent = JSON.parse(this.broadcastContent)
          console.log(this.broadcastContent)
          for (let i = 0; i < this.broadcastContent.length; i++) { //i 表示几个20s
            this.ConclusionItem[i] = JSON.parse(JSON.stringify(this.DiseaseItem)) // 注意！这里是个坑！如果直接赋值，不进行转换的话，不是标准的对象或数组，最后会导致所有20s的结论一样，谨记！
            console.log(typeof (this.DiseaseItem))
            for (let j = 0; j < this.ConclusionItem[i].length; j++) {
              let name = this.ConclusionItem[i][j]["name"]
              // console.log(name)
              if (name in this.broadcastContent[i]) {
                this.ConclusionItem[i][j]["value"] = this.broadcastContent[i][name]
              }
            }
            // console.log(this.ConclusionItem)
          }
          console.log(this.ConclusionItem)
          this.dialogVisible = true
          this.pictLoading = true
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    routeReport(e) {
      console.log(e);
      this.$router.push({
        name: 'EightHolterReport',
        query: {

          "dynamicRecordId": e.deid,
          "dynamicRecordCreateTime": e.createTime,
          "dynamicRecorDuration": e.duration
        }
      })
    },

    //设置表头行的样式
    tableHeaderColor() {
      return "color:#000 ;font-size:18px;text-align:center";
    },
    //设置播报记录的条件格式，根据数值显示不同颜色


    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 2) {
        if (row.xdgs > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 3) {
        if (row.xdgh > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 4) {
        if (row.xlbq > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 5) {
        if (row.dxtb > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 6) {
        if (row.fxzb > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 7) {
        if (row.jjxzb > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 8) {
        if (row.sxzb > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 9) {
        if (row.ssxxdgs > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 10) {
        if (row.sxxdgs > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 11) {
        if (row.fxyb > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 12) {
        if (row.fc > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 13) {
        if (row.qtyc > 50) {
          return 'background: #F1948A ';
        }
      }

    },

    // 心狗医生--复核
    Check2Doctor(e) {
      console.log(e.userDescribe)
      if (this.$store.state.module.crossPlatform) {
        console.log("object11111");
        this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
      }
      this.$http.adornHeader(this.header)
      //医生复核
      this.$http({
        url: this.$http.adornUrl("/personal/relationconsult/createRecord"),
        method: "post",
        data: this.$http.adornData({
          uid: this.uid, type: "ecg8_dynamic", userDescribe: e.userDescribe, recordId: e.sid
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log('返回的数据')
          console.log(data)
          e.checkStatus = '待复核'
          this.$message({
            message: '成功向医生发出复核申请',
            type: 'success'
          })
        }
        else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消申请'
        });
      });

    },
    CheckResult(e) {
      this.checkdataList = []
      this.checkdataListVisible = true
      console.log(e)
      if (this.$store.state.module.crossPlatform) {
        console.log("object11111");
        this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
      }
      this.$http.adornHeader(this.header)
      this.$http({
        url: this.$http.adornUrl("/personal/relationconsult/getRecord"),
        method: "post",
        data: this.$http.adornData({ type: "ecg8_dynamic", recordId: e.sid, }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.checkdataList = data.list
          console.log(this.checkdataList)
        }
        else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      })
    },
    getinfo() {
      if (this.$store.state.module.crossPlatform) {
        console.log("object11111");
        this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
      }
      this.$http.adornHeader(this.header)
      this.$http({
        url: this.$http.adornUrl('/personal/info/getBasicInfo'),
        method: 'get',
        params: this.$http.adornParams({ uid: this.uid })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据")
          this.realName = data.realName
          // this.age = data.age
          // this.gender = data.gender
          // var genderMap = { 0: "女", 1: "男" }
          // this.gender = genderMap[this.gender]
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },
    download_table() {
      // Create a new array with updated key names
      const excel_detail = this.tableData
      // Convert data to a workbook
      const wb = XLSX.utils.book_new();
      const ws1 = XLSX.utils.json_to_sheet(excel_detail);
      XLSX.utils.book_append_sheet(wb, ws1, "详细记录");
      const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
      // Save the workbook
      saveAs(new Blob([wbout], { type: 'application/octet-stream' }), this.realName+'_八导联Holter心电检测记录.xlsx');
    },
    updateRemark(e){
      console.log(e.remark)
      this.$http({
        url: this.$http.adornUrl("/personal/dynamicecg8/updateRemark"),
        method: "post",
        params: this.$http.adornParams({
          deid: e.deid,
          remark: e.remark,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据2023年1月9日");
          console.log(data);
          this.$message({
            message: '保存成功',
            type: 'success'
          });
        } else {
          console.log(data);
          this.$message({
            message: data.msg,
            type: "warning",
          });
        }
      });
    }
  },
  created() {
    if (this.$route.query.crossPlatform != null && this.$route.query.crossPlatform != undefined) {
      // 小程序端请求
      this.crossPlatformStatus = true
    }
    if (this.$route.query.uid) {
      this.uid = this.$route.query.uid
    } else {
      this.uid = this.$store.state.personal_module.uid
    }
  },
  mounted() {
    // 显示数据
    this.getDataList()
    this.getinfo()
    this.isCrossPlatJump = !this.$store.state.module.crossPlatform   // isCrossPlatJump 是否在个人平台 

  }
};
</script>
<style scoped>
.blank {
  border-radius: 4px;
  height: 18px;
}

.form-name {
  display: inline-block;
  font-weight: bold;
  font-size: 18px;
}

.xingou-blue {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}

.xingou-blue:hover,
.xingou-blue:focus {
  background: #4da1eb;
  border-color: #4da1eb;
  color: #fff
}

::v-deep .el-descriptions .is-bordered .el-descriptions-item__cell {
  font-size: 14px;
  padding: 0px 3px !important;
}

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

::v-deep .el-dialog__body {
  padding: 0px 20px !important
}
</style>
